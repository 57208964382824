@import '../../colors';

main {
  font-size: 0;

  button {
    border: none;
    cursor: pointer;
    outline: none;
  }

  .hidden {
    display: none;
  }

  .blur {

    &.on {
      background-color: #000000;
      height: 100%;
      left: 0;
      opacity: 0.4;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  .addSetButton {
    bottom: 15px;
    font-size: 0;
    margin: 0;
    position: fixed;
    right: 15px;

    button {
      background: transparent;
      font-size: 0;
      padding: 0;

      img {
        background: $colorPurple;
        border-radius: 50px;
        box-shadow: 2px 2px 6px 0 #000;
        transition: 0.3s;
        width: 50px;

        &:hover {
          background: $colorYellow;
        }
      }
    }
  }
}