@import '../../colors';

.set {
  align-items: center;
  background: $colorYellow;
  box-shadow: 0 1px 6px 0 #000;
  display: flex;
  margin-top: 10px;
  padding: 10px 0;

  @media (min-width: 650px) {
    padding: 10px 50px;
  }

  &:last-child {
    margin-bottom: 80px;
  }

  button {
    background: transparent;
    font-size: 0;
    margin: auto 5px;
    padding: 0;
  }

  .setsLeft {
    align-items: center;
    display: flex;
    font-size: 15px;

    @media (min-width: 330px) {
      font-size: 16px;
    }

    @media (min-width: 360px) {
      font-size: 17px;
    }

    @media (min-width: 500px) {
      font-size: 20px;
    }

    .decrease {

      img {
        border: 2px solid;
        border-radius: 20px;
        width: 14px;

        @media (min-width: 330px) {
          width: 15px;
        }

        @media (min-width: 360px) {
          width: 16px;
        }

        @media (min-width: 500px) {
          width: 18px;
        }
      }

      &.invisible {
        visibility: hidden;
      }
    }

    .num {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  .exerciseName {
    flex: 1;
    font-size: 15px;
    font-weight: bold;
    word-break: break-word;

    @media (min-width: 330px) {
      font-size: 16px;
    }

    @media (min-width: 360px) {
      font-size: 17px;
    }

    @media (min-width: 500px) {
      font-size: 22px;
    }
  }

  .edit img, .delete img {
    width: 20px;

    @media (min-width: 500px) {
      width: 24px;
    }
  }
}
