@import '../../colors';

header {
  align-items: center;
  background: $colorPurple;
  box-shadow: 0 1px 6px 0 #000;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0 5px;
  position: relative;
  z-index: 1;

  @media (min-width: 650px) {
    padding: 0 50px;
  }

  img {
    background: $colorYellow;
    border-radius: 20px;
    box-shadow: 0 1px 6px 0 #000;
    cursor: pointer;
    padding: 2px;
    transition: 0.3s;

    &:hover, &.active {
      background: $colorBlue;
    }

    &.active:hover{
      background: $colorYellow;
    }
  }

  h1.pageTitle {
    color: $colorYellow;
    cursor: pointer;
    font-size: 35px;
    margin: 0;
  }
}
