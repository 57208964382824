@import '../../colors';

@keyframes appear {
  0% { top: -50px; height: 0; padding: 0; }
  100% { top: 0; }
}

@keyframes disappear {
  0% { top: 0; }
  100% { top: -50px; height: 0; padding: 0; }
}

.timerButtons {
  animation: appear 0.9s;
  animation-fill-mode: forwards;
  background: #582a72;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 2px -1px #000;
  display: inline-block;
  padding: 8px 0 4px;
  position: relative;
  top: 0;
  vertical-align: top;
  width: calc(100% / 2.9);

  &.off {
    animation: disappear 0.9s ease-in;
    animation-fill-mode: forwards;
  }

  button {
    background: $colorYellow;
    border: none;
    border-radius: 4px;
    font-size: 0;
    margin: 2px;
    padding: 0 5px;
    transition: 0.3s;

    @media (min-width: 360px) {
      margin: 3px;
    }

    &:hover {
      background: $colorBlue;
    }

    img {
      width: 18px;

      @media (min-width: 330px) {
        width: 20px;
      }

      @media (min-width: 360px) {
        width: 22px;
      }

      @media (min-width: 500px) {
        width: 24px;
      }
    }
  }
}
