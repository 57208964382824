@import '../../colors';

@keyframes appear {
  0% { top: -70px; height: 0; padding: 0; }
  100% { top: 0; }
}

@keyframes disappear {
  0% { top: 0; }
  100% { top: -70px; height: 0; padding: 0; }
}

.timer {
  animation: appear 0.9s;
  animation-fill-mode: forwards;
  background: #582a72;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 2px -1px #000;
  display: inline-block;
  padding-top: 6px;
  position: relative;
  top: 0;
  vertical-align: top;
  width: calc(100% / 3.8);

  &.off {
    animation: disappear 0.9s ease-in;
    animation-fill-mode: forwards;
  }

  .invisible {
    visibility: hidden;
  }

  span {
    color: $colorYellow;
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;

    @media (min-width: 330px) {
      font-size: 18px;
    }

    @media (min-width: 360px) {
      font-size: 21px;
    }

    @media (min-width: 500px) {
      font-size: 23px;
    }

    button {
      background: transparent;
      display: block;
      padding: 0 4px;

      img {
        width: 14px;

        @media (min-width: 330px) {
          width: 16px;
        }

        @media (min-width: 360px) {
          width: 19px;
        }
      }
    }
  }
}
