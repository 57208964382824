@import '../../colors';

@keyframes appear {
  0% { height: 0; padding: 0; top: -120px; }
  100% { top: 0; }
}

@keyframes disappear {
  0% { top: 0; }
  100% { height: 0; padding: 0; top: -120px; }
}

.notifySettings {
  animation: appear 0.9s;
  animation-fill-mode: forwards;
  background: #582a72;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 2px -1px #000;
  display: inline-flex;
  justify-content: center;
  padding: 6px 0 6px;
  position: relative;
  top: -120px;
  vertical-align: top;
  width: calc(100% / 2.56);

  &.off {
    animation: disappear 0.9s ease-in;
    animation-fill-mode: forwards;
  }

  img.info {
    cursor: pointer;
  }

  .settings {
    margin-left: 6px;

    @media (min-width: 330px) {
      margin-left: 10px;
    }

    div {
      align-items: flex-start;
      display: flex;

      button {
        background: transparent;
        border-bottom: 2px solid transparent;
        font-family: 'PT Mono', sans-serif;
        font-size: 13px;
        padding: 3px;

        @media (min-width: 330px) {
          font-size: 16px;
        }

        &.checked {
          border-color: $colorYellow;
        }
      }
    }
  }
}

.instr {
  background: $colorYellow;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  font-size: 13px;
  line-height: .9;
  margin: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 115px;
  transition: 1.2s;
  width: 50%;

  @media (min-width: 330px) {
    font-size: 16px;
    top: 119px;
  }

  &.off {
    top: -160px;

    @media (min-width: 330px) {
      top: -210px;
    }
  }

  ul {
    margin: 0;
    padding: 0 0 0 18px;
  }

  hr {
    border-bottom: 0;
    border-color: #000;
  }
}
