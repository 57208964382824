@import '../../colors';

.setEditor {

  &.on {
    background: $colorPurple;
    border-radius: 5px;
    height: 92px;
    margin: auto;
    max-width: 400px;
    padding: 5px;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 80%;
    z-index: 1;

    .close {
      background: transparent;
      display: block;
      font-size: 0;
      margin: 0 0 5px auto;
      padding: 0;
    }

    .inputAndButtons {
      float: left;
      width: 70%;

      input {
        border: none;
        border-radius: 4px;
        box-sizing: border-box;
        display: block;
        font-family: 'PT Mono', sans-serif;
        margin: 0 auto 7px;
        max-width: 300px;
        padding: 5px 0;
        text-align: center;
        width: 100%;
      }

      button {
        background: $colorYellow;
        border-radius: 4px;
        font-family: 'PT Mono', sans-serif;
        font-size: 16px;
        margin: 2px;
        padding: 3px 10px;
      }
    }

    .numbers {
      float: left;
      text-align: right;
      width: 30%;

      > div {
        font-size: 0;

        button {
          background: $colorYellow;
          border-radius: 3px;
          font-family: 'PT Mono', sans-serif;
          font-size: 15px;
          margin: 1px;

          &.checked {
            background: $colorBlue;
          }
        }
      }
    }
  }
  &.off {
    display: none;
  }
}
