@import './components/App/app';
@import './components/Header/header';
@import './components/SetEditor/setEditor';
@import './components/Set/set';
@import 'components/TimerButtons/timerButtons';
@import 'components/Timer/timer';
@import 'components/NotifySettings/notifySettings';
@import 'colors';

body {
  background: $colorBlue;
  font-family: 'PT Mono', sans-serif;
  margin: 0;
  text-align: center;
}
